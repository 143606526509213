body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}
a.active {
  color: orange;
}

nav {
  padding-top: 20em;
  padding-bottom: 20em;
}

.panel {
  padding-top: 1em;
  padding-bottom: 1em;
}

/* position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 1.5em;
  height: 1.25em;
  left: 1.5em;
  top: 1.5em;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24em;
  width: 24em;
}

/* color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Menu positioning */
.bm-menu-wrap {
  bottom: 0;
  left: 0;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.clickable {
  cursor: pointer;
}

.ui.button.basic.link,
.ui.button.basic.link:hover,
.ui.button.basic.link:focus {
  box-shadow: 0 0 !important;
}

